.support {
  @include flexbox;
  @include align-items(flex-start);
  @include flex-direction(column);
  @include justify-content(space-between);
  @include flex(0, 0, 35%);

  min-height: 25rem;
  height: 100%;

  padding: 4.5rem 3.5rem;

  background-image: linear-gradient(rgba(111, 108, 176, .8), rgba(111, 108, 176, .8)), url(../assets/support.png);
  background-size: cover;
  background-position: center;

  border-radius: 3px;

  box-shadow: var(--box-shadow-small);

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 40%);

    padding: 3.5rem 2.5rem;

    margin-left: -10px;
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 100%);

    margin-left: 0;

  }
}

.support__heading {
  @extend .slider__subheading;

  font-size: 2.3rem;

  color: var(--color-white);
}

.support__paragraph {
  @extend .paragraph;

  padding: 1.5rem 0;

  color: var(--color-grey-1);
}

.support__link {
  @include flexbox;
  @include align-items(center);
  @extend .support__link-text;

  color: var(--color-grey-1);
}

.support__link--phone {

  color: var(--color-yellow);
}

.support__link-icon {
  @include flexbox;

  margin-right: 1.2rem;
}
