.toolbar {
  @include flexbox;

  @include align-items(center);
  @include justify-content(space-between);

  font-size: 1.4rem;

  &:not(:last-child) {
    padding-bottom: 25px;
  }

  &:first-child {

    @media only screen and (max-width: $bp-medium) {
      @include flex-direction(column);
      @include justify-content(center);
    }
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 1.8rem;
  }

}

.toolbar__contact-info {
  @include flex-center;
  
  margin-right: auto;

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);

    margin: 0 0 2rem;
  }
}

.toolbar__contact-info-phone {
  margin-right: 4.5rem;

  @media only screen and (max-width: $bp-medium) {
    margin: 0 0 1.5rem;
  }
}

.toolbar__contact-info-phone,
.toolbar__contact-info-mail {
  @include flex-center;

  color: var(--color-grey-1);
}

.toolbar__social-list {
  @include flexbox;
  @include no-bullets;

  padding: 0;
  margin: 0 30px 0 0;
}

.toolbar__social-single {
  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.toolbar__social-link {
  @include flex-center;

  padding: 5px;

  transition: all .3s ease-in-out;

  &--header {
    color: var(--color-primary-light);
  }

  &--header:hover,
  &--header:focus {
    color: var(--color-white);
  }

  &--footer {
    color: var(--color-white);
  }
  
  &--footer:hover,
  &--footer:focus {
    color: var(--color-primary-light);
  }
}

.toolbar__languages {
  @include flexbox;
}

.toolbar__languages-single {
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}
