.features-icon-list {
  @include flexbox;
  @include flex-wrap(wrap);
  @include no-bullets;

  padding: 0;
  margin: 0;
}

.list-icon-item__miniature {
  margin-right: 2rem;

  transition: color .3s ease-out;

  svg {
    transition: transform .3s ease-out;
  }

}

.list-icon-item__text-heading {
  @extend .features__heading;

  transition: color .3s ease-out;
}

.list-icon-item__text-paragraph {
  @extend .features__paragraph;
}

.features__item:hover {
  .list-icon-item__miniature {
    color: var(--color-yellow);

    svg {
      transform: scale(1.3);
    }
  }

  .list-icon-item__text-heading {
    color: var(--color-yellow);
  }
}