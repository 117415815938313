.mobile-menu {
  @include flexbox;
  align-self: flex-end;

  position: relative;

  margin-bottom: 5px;
}

.mobile-menu__overlay {
  position: fixed;

  display: none;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  z-index: 10;

  background-color: #000;
}

.mobile-menu__overlay--show {
  display: block;

  opacity: .7;

  animation: showOverlay .3s ease-in;
}

.mobile-menu__button {
  @include flex-center;

  width: 38px;
  height: 30px;

  padding: 8px;

  background-color: transparent;

  border: 2px solid var(--color-white);
  border-radius: 3px;
}

.mobile-menu__button-icon {
  position: relative;
  
  display: inline-block;

  width: 100%;
  height: 2px;

  border-radius: 3px;

  background-color: var(--color-white);

  &::before,
  &::after {
    position: absolute;

    left: 0;

    width: 100%;
    height: 2px;

    border-radius: 3px;

    background-color: var(--color-white);

    content: '';
  }

  &::before {
    top: 6px;
  }

  &::after {
    bottom: 6px;
  }

}

.mobile-menu__nav {
  @include flexbox;
  @include flex-direction(column);

  position: fixed;

  top: 0;
  right: 0;

  width: 230px;
  height: 100%;
  
  padding: 2.5rem 4rem 4rem;

  z-index: 100;

  overflow: scroll;

  background-color: var(--color-primary);

  transform: translateX(230px);

  transition: transform .3s ease-in-out;
}

.mobile-menu__nav--open {
  transform: translateX(0);
}

.mobile-menu__list {
  @include no-bullets;
  
  padding: 2.5rem 0 0;
  margin: 0;
  
  .nav__item {
    padding: 0 0 20px;
    margin: 0 0 20px;

    border-bottom: 1px solid var(--color-white);
  }
}

.nav__link-mobile,
.nav__link-mobile:link,
.nav__link-mobile:visited {
  padding: 10px 0;

  font-size: 12px;

  letter-spacing: .5px;

  text-transform: uppercase;

  color: var(--color-white);
}

.nav__link-mobile {
  &.nav__link-mobile--active {
    color: var(--color-yellow);
  }
}

.mobile-menu__close-btn {
  width: 25px;
  height: 25px;

  border: 0;

  background-color: transparent;
}

.mobile-menu__close-btn-icon {
  position: relative;
  
  display: inline-block;

  width: 100%;
  height: 100%;

  &::before,
  &::after {
    position: absolute;

    top: 10px;
    left: 0;

    width: 100%;
    height: 2px;

    border-radius: 3px;

    background-color: var(--color-white);

    content: '';
    
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }

}

@keyframes showOverlay {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: .7;
  }
}