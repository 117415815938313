.main-slider {
  // min-height: 70vh;

}

.main-slider__list {
  @include no-bullets;

  padding: 0;
  margin: 0;
}

.main-slider__single {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-start);
  position: relative;
  min-height: 80vh;

  padding: 180px 70px 0;

  background-size: cover;
  background-position: bottom;

  overflow: hidden;

  @media only screen and (max-width: $bp-large) {
    min-height: 65vh;

    padding: 0 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    min-height: 50vh;

    padding: 0 20px;
  }

  &--shipwreck-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url(../assets/shipwreck-tour.png);
  }

  &--olympia-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url(../assets/olympia-tour.png);
  }

  &--turtle-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url(../assets/tourtle-tour.png);
  }
}

.main-slider__player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-fallback {
  .main-slider__video-wrapper {
    &:after {
      background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url(../assets/shipwreck-tour.png);
    }
  }
}

.main-slider__video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5));
    content: '';
  }
}

.main-slider__content {
  z-index: 2;
  width: 100%;
  max-width: 1180px;

  margin: 0 auto;
}

.main-slider__text-main-heading {
  @extend .slider__main-heading;

  display: block;
}

.main-slider__text-subheading {
  @extend .slider__subheading;
}

.main-slider__text-paragraph {
  @extend .slider__paragraph;
}

.main-slider__cta {

}
