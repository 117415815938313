:root {
  --color-primary: #302e80;
  --color-primary-light: #6f6cb0;
  --color-primary-dark: #1c1b68;

  --color-yellow: #ffa127;
  --color-success: #7fba00;

  --color-grey-1: #e6e6e6;
  --color-grey-2: #ababab;
  --color-grey-3: #303030;
  --color-grey-4: #838383;
  --color-grey-5: #f3f3f3;

  --color-dark-1: #333333;
  --color-dark-2: #181818;
  --color-dark-3: #202020;

  --color-white: #fff;
  --color-black: #000;
  --color-red: #e85e34;

  --box-shadow-big: 0 2rem 5rem rgba(0, 0, 0, 0.5);
  --box-shadow-medium: 0 2rem 5rem rgba(0, 0, 0, 0.3);
  --box-shadow-small: 0 2rem 10rem rgba(0, 0, 0, 0.13);
}

// Media queries

$bp-largest: 1200px; // 1200px
$bp-large: 991px; // 900px
$bp-medium: 768px; // 700px
$bp-small: 600px; // 600px
$bp-smallest: 400px; // 400px
