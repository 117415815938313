.booking {
  position: absolute;

  width: 100%;

  top: -50.5rem;

  @media only screen and (max-width: $bp-large) {
    position: relative;

    top: 0;

    width: 47%;
  }

  @media only screen and (max-width: $bp-medium) {
    width: 100%;
  }
}

.booking__price-label {
  padding: 1.3rem 0;

  background-color: rgba(48, 46, 128, 0.7);

  text-align: center;

  @media only screen and (max-width: $bp-large) {
    background-color: var(--color-primary);
  }
}

.booking__price-details {
  @include flex-center;

  padding: 3rem 0;

  background-color: rgba(111, 108, 176, 0.7);

  @media only screen and (max-width: $bp-large) {
    background-color: var(--color-primary-light);
  }
}

.booking__price-label-text {
  @extend .slider__paragraph;
}

.booking__panel {
  padding-bottom: 3.5rem;
  margin: 0 -2px;

  background-color: var(--color-white);

  box-shadow: var(--box-shadow-small);

  border: 2px solid var(--color-grey-1);
  border-top: 0;
  border-radius: 3px;
}

.booking__price-icon {
  @include flexbox;

  margin-right: 1.5rem;

  transform: rotate(-45deg);

  color: var(--color-white);
}

.booking__price-text {
  @include flex-center;
  @extend .paragraph;

  color: var(--color-white);
}

.booking__price-text--highlited {
  @extend .category-page__heading;

  margin-left: 1.5rem;

  color: var(--color-white);
}

.booking__form-header {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-start);

  background-color: var(--color-grey-5);
}

.booking__form-heading {
  @extend .paragraph;

  padding: 2rem 2.5rem;

  background-color: var(--color-white);
  color: var(--color-black);
}

.booking__form-single-row {
  @include flexbox;
  @include flex-direction(column);

  padding: 1rem 2.5rem;
}

.booking__form-label {
  @extend .paragraph;

  margin-bottom: 1rem;
}

.booking__form-input,
.booking__form-text-area {
  @extend .paragraph;

  padding: 1.7rem 1.2rem;

  max-width: 100%;

  border: 2px solid var(--color-grey-5);

  color: var(--color-black);
}

.booking__form-text-area {
  max-height: 15rem;
  min-width: 100%;
}

.booking__form-message {
  text-align: center;
  color: var(--color-grey-3);
}
