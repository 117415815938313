.container {
  max-width: 1180px;

  margin: 0 auto;
}

.container--flex {
  @include flexbox;
  @include justify-content(space-between);

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
  }
}

.container--wrap {
  @include flex-wrap(wrap);
}

.section {
  padding: 0 70px;

  @media only screen and (max-width: $bp-large) {
    padding: 0 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px;
  }
}

.category-page {
  padding: 70px;

  background-color: var(--color-grey-5);

  @media only screen and (max-width: $bp-large) {
    padding: 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 50px 20px;
  }
}

.single-offer-page {
  padding: 70px;

  @media only screen and (max-width: $bp-large) {
    padding: 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 40px 20px;
  }

  &__sidebar-wrapper {
    @media only screen and (max-width: $bp-large) {
      width: 47%;
    }

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }
  }
}
