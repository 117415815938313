.single-offer-header {
  background-size: cover;
  background-position: bottom;

  padding: 180px 70px 0;

  overflow: hidden;

  &--tourtles-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/tourtle-tour.png');
  }

  &--tourtles-tour-sunset {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/tourtle-tour-sunset.png');
  }

  &--shipwreck-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/shipwreck-tour.png');
  }

  &--shipwreck-tour-small {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/shipwreck-small-boat.png');
  }

  &--kefalonia-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/kefalonia-tour.png');
  }

  &--olympia-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/olympia-tour.png');
  }

  &--scuba-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/scuba-tour.png');
  }

  &--real-zak {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/real-zak.png');
  }

  &--horse-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/horse-tour.png');
  }

  &--fishing-tour {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/fishing-tour.png');
  }

  &--cave-cruises {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/cave-cruises.png');
  }

  &--transport {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/transport-header.png');
  }

  &--auto-moto {
    background-image: linear-gradient(to right,rgba(106, 161, 194, .3) 7%, rgba(0, 0, 0, .5)), url('../assets/auto-moto-header.png');
  }

  &--motor-boat {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/motor-boat-offer.png');
  }

  &--round-trip {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/day-tour.png');
  }

  &--around-island-cruise {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/zante-night.png');
  }

  &--sunset-tour {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/sunset-tour.png');
  }

  &--jeep-safari {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/jeep-safari.png');
  }

  &--water-park {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/water-park.png');
  }

  &--archery {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/archery-list.png');
  }

  @media only screen and (max-width: $bp-large) {
    padding: 0 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px;
  }

}

.single-offer-header__wrapper {
  @include flexbox;
  @include align-items(flex-end);
  @include justify-content(space-between);

  min-height: 50rem;

  @media only screen and (max-width: $bp-large) {
    min-height: 34rem;
  }

  @media only screen and (max-width: $bp-large) {
    min-height: 31rem;
  }
}

.single-offer-header__heading-wrapper {
  padding: 0 0 5rem;
}

.single-offer-header__heading {
  @extend .heading-secondary;

  padding: .5rem 1.5rem;
  margin-right: 35rem;

  color: #fff;

  background-color: rgba(48, 46, 128, .65);

  @media only screen and (max-width: $bp-large) {
    margin-right: 0;
  }
}
