@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(0);
    opacity: 0;
  }

  30% {
    transform: translateY(-50%) scale(1.1);
    opacity: 1;
  }

  60% {
    transform: translateY(-50%) scale(1.3);
    opacity: 0.4;
  }

  100% {
    transform: translateY(-50%) scale(1.5);
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-25deg);
  }

  100% {
    transform: rotate(25deg);
  }
}
