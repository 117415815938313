.nav {
  align-self: flex-end;
}

.nav__list {
  @include flexbox;
  @include no-bullets;
  
  padding: 0;
  margin: 0;
}

.nav__item {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.nav__link,
.nav__link:link,
.nav__link:visited {
  position: relative;

  padding: 10px 0;

  letter-spacing: .5px;

  color: var(--color-grey-1);

  &::after {
    @include transition(all, .3s);

    position: absolute;

    bottom: -44%;
    left: 0;

    width: 100%;
    height: 3px;

    background-color: transparent;
    
    content: '';
  }

  &:hover::after,
  &:focus::after {
    background-color: var(--color-primary-light);
  }

  &:hover,
  &:focus {
    color: var(--color-primary-light);
  }

}

.nav__link {
  &.nav__link--active {
    color: var(--color-primary-light);
  }

  &.nav__link--active::after {
    background-color: var(--color-primary-light);
  }
}


