.popular-tours__item {
  box-shadow: var(--box-shadow-small);
}

.popula-tours__link {
  &,
  &:visited,
  &:link {
    display: block;

    height: 45rem;

    color: inherit;

    border-radius: 3px;
  }
}

.popular-tours__item-image {
  position: relative;

  height: 55%;

  background-image: url(../assets/tourtle.png);
  background-size: cover;
  background-position: bottom;
}

.popular-tours__item--promo {
  .popular-tours__item-promo-label {
    position: absolute;

    @include flex-center;

    width: 37%;
    height: 4.2rem;

    top: 10%;
    left: -4%;

    border-radius: 2px;

    background-color: var(--color-red);

    //TODO: improve this element.

    &::after {
      position: absolute;

      bottom: -.4rem;
      left: .1rem;

      width: 0;
      height: 0;

      border-left: .9rem solid transparent;
      border-right: .9rem solid transparent;

      border-top: .9rem solid rgba(232, 94, 52, 0.5);

      content: '';

      transform: rotate(-134deg);
    }
  }
}

.popular-tours__item-promo-label {
  display: none;
}

.popular-tours__item-details {
  height: 45%;

  padding: 3.2rem 2.5rem;

  box-shadow: var(--box-shadow-small);

  background-color: var(--color-white);
}

.popular-tours__item-header {
  @include flexbox;
  @include justify-content(space-between);
}

.popular-tours__item-text-heading {
  @extend .popular-tours__item-heading;

  color: var(--color-black);
}

.popular-tours__item-price {
  @extend .slider__subheading;

  font-size: 2.6rem;

  color: var(--color-primary);
}

.popular-tours__item-price--previous {
  @extend .features__paragraph;

  display: block;

  text-align: right;
  text-decoration: line-through;
}

.popular-tours__item-duration {
  @include flexbox;
  @include align-items(center);

  margin-bottom: 1.5rem;
}

.popular-tours__icon {
  @include flexbox;

  margin-right: .9rem;
}

.popular-tours__item-label {
  @extend .popular-tours__duration-time;
}

.popular-tours__item-promo-text {
  @extend .features__heading
}

.popular-tours__item-review {
  @include flexbox;
}

.popular-tours__item-stars-single {
  @include flexbox;

  &:not(:last-child) {
    margin-right: .3rem;
  }
}
