.single-offer-overview {
  padding: 6.5rem 70px 1.5rem;

  background-color: var(--color-grey-5);

  @media only screen and (max-width: $bp-large) {
    padding: 6.5rem 40px 1.5rem;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 5rem 20px;
  }
}

.single-offer-overview__list {
  @include flexbox;
  @include flex-wrap(wrap);
  @include no-bullets;

  width: 65%;

  padding: 0;

  @media only screen and (max-width: $bp-large) {
    width: 100%;
  }
}

.single-offer-overview__item {
  @include flexbox;
  @include flex(0, 0, 49%);
  @include align-items(center);
  @include justify-content(flex-start);
  
  margin-bottom: 1.5rem;

  @media only screen and (max-width: $bp-small) {
    @include flex(0, 0, 100%);
    
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.single-offer-overview__icon {
  @include flex-center;

  width: 30px;

  margin-right: 1.5rem;

  color: var(--color-primary-light);
}

.single-offer-overview__paragraph {
  @extend .single-offer-overview__item-text;

  color: var(--color-black);
}

.single-offer-overview__separator {
  height: 7rem;

  background-color: var(--color-grey-1);

  @media only screen and (max-width: $bp-small) {
    height: 5rem;
  }
}