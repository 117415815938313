.u-text-center { text-align: center;}

.u-margin-bottom-small { margin-bottom: 1.5rem; }
.u-margin-bottom-medium { margin-bottom: 2.5rem; }
.u-margin-bottom-big { margin-bottom: 3.5rem; }

.u-margin-top-small { margin-top: 1.5rem; }
.u-margin-top-medium { margin-top: 2.5rem; }
.u-margin-top-big { margin-top: 3.5rem; }

.u-margin-right-medium { margin-right: 2.5rem; }

.u-align-center { align-self: center; }

.visually-hidden {
  position: absolute;
  
  width: 1px;
  height: 1px;
  
  padding: 0;
  
  overflow: hidden;
  
  clip: rect(1px, 1px, 1px, 1px);
  
  border: 0;
}
