.category-all-items {
  @include flex(1);

  @media only screen and (max-width: $bp-large) {
    margin-bottom: 50px;
  }
}

.category-all-items__list {
  @include no-bullets;

  width: 85%;

  padding: 0;
  margin: 0;

  @media only screen and (max-width: $bp-largest) {
    width: 93%;
  }

  @media only screen and (max-width: $bp-large) {
    width: 100%;
  }
}