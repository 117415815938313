.footer {
  background-color: var(--color-dark-3);
}

.footer__wrapper {
  @include flexbox;

  padding: 7rem 0 5rem;

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
  }
}

.footer__single {
  @include flex(0, 0, 33%);
  @include flexbox;
  @include flex-direction(column);
  @include align-items(flex-start);

  padding: 1.5rem 3rem;

  &:not(:last-child) {
    border-right: 1px solid var(--color-grey-3);
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 100%);

    padding: 2.5rem 0;
    margin: 0 20px;

    &:not(:last-child) {
      border-right: 0;
      border-bottom: 1px solid var(--color-grey-3);
    }
  }
}

.footer__text-heading {
  @extend .heading-tertiary;
}

.footer__text-paragraph {
  @extend .paragraph;
}

.footer__copyright {
  @include flex-center;
  
  padding: 4rem 1rem;
  
  background-color: var(--color-dark-2);
}

.footer__text-copyright {
  font-size: 14px;
  
  color: var(--color-grey-4);
}

.footer__about-link {
  display: block;

  width: 60px;
  height: auto;

  margin-top: 4rem;

}

.footer__about-img {
  max-width: 100%;
  height: auto;
}
