body {
  font-family: $body-font;
  font-weight: 400;
  color: var(--color-grey-1);
  line-height: 1.9;
}

h1, h2, h3, h4 {
  // font-family: $heading-font;
  font-weight: 300;
}

.paragraph {
  font-size: 1.4rem;
  letter-spacing: .7px;

  color: var(--color-grey-2);

  @media only screen and (max-width: $bp-smallest) {
    font-size: 1.7rem;
  }
}

.heading-secondary {
  font-size: 2.9rem;
  font-weight: 400;

  line-height: 1.5;

  letter-spacing: 2px;

  @media only screen and (max-width: $bp-large) {
    line-height: 30px;
  }
}

.heading-tertiary {
  font-size: 1.4rem;
  font-weight: 700;

  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.slider__main-heading {
  font-size: 6.2rem;
  font-weight: 700;

  line-height: 1.1;

  letter-spacing: 4px;

  text-transform: uppercase;

  @media only screen and (max-width: $bp-large) {
    font-size: 5.2rem;

    letter-spacing: 3px;
  }
}

.slider__subheading {
  font-size: 2.3rem;
  font-weight: 500;

  line-height: 1.3;

  letter-spacing: 2px;
  
  @media only screen and (max-width: $bp-large) {
    font-size: 1.8rem;
  }
}

.slider__paragraph {
  font-size: 1.8rem;

  letter-spacing: 1.5px;

  @media only screen and (max-width: $bp-large) {
    font-size: 1.5rem;
  }
}

.features__heading {
  font-size: 1.4rem;
  font-weight: 600;

  letter-spacing: 1.2px;

  text-transform: uppercase;
}

.features__paragraph {
  font-size: 1.4rem;

  color: var(--color-grey-2);
}

.popular-tours__item-heading {
  font-size: 1.7rem;
  font-weight: 700;

  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.popular-tours__duration-time {
  font-size: 1.5rem;

  color: var(--color-grey-2);
}

.support__link-text {
  font-size: 2rem;
}

.subsection-heading {
  font-size: 2.1rem;
}

.category-page__heading {
  font-size: 4.2rem;
  font-weight: 700;

  letter-spacing: 1.5px;
}

.category-page__item-heading {
  font-size: 2rem;
  font-weight: 600;

  line-height: 26px;

  letter-spacing: 1.3px;

  color: var(--color-black);
}

.category-page__item-price {
  font-size: 2.6rem;
  font-weight: 200;

  letter-spacing: 1.3px;
}

.single-offer-overview__item-text {
  font-size: 1.5rem;

  font-weight: 500;

  letter-spacing: 1.1px;
}