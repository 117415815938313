.video-section {
  padding: 0 70px 70px;

  @media only screen and (max-width: $bp-large) {
    padding: 0 40px 70px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 3rem 20px 30px;
  }
}

.video-section--flex {
  @include flexbox;
  @include justify-content(space-between);

  @media only screen and (max-width: $bp-large) {
    @include flex-direction(column);
  }
}

.video__wrapper {
  @include flex(0, 0, 60%);

  position: relative;

  height: 40rem;

  box-shadow: var(--box-shadow-small);

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 100%);
  }
}

.video__wrapper iframe,
.video__wrapper object, 
.video__wrapper embed { 
  position: absolute; 
  
  top: 0;
  left: 0; 
  
  width: 100%; 
  height: 100%; 
}

.video__details {
  @include flex(0, 0, 35%);

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 40%);

    margin-bottom: 30px;
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 100%);
  }
}

.video__heading {
  @extend .heading-secondary;
  @include flexbox;
  @include align-items(center);

  margin-bottom: 20px;

  color: var(--color-black);
}

.video__icon {
  @include flexbox;
  margin-right: 1.7rem;

  color: var(--color-primary-light);
}

.video__description-text {
  @extend .paragraph;
}
