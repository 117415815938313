.popular-services__item {
  @include flex(0, 0, 20%);

  height: 37rem;

  background-size: cover;
  background-position: center;

  overflow: hidden;

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 50%);

    &:last-child {
      display: none;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 100%);

    &:last-child {
      display: list-item;
    }
  }

  &--wrapper {
    @include flexbox;
    @include align-items(flex-start);
    @include justify-content(flex-end);
    @include flex-direction(column);
    @include transition(background-color .2s ease-in-out);

    position: relative;

    height: 100%;

    padding: 1.5rem 2.5rem;

    &::after {
      position: absolute;
  
      bottom: -.5rem;
      left: 0;
  
      width: 100%;
      height: .5rem;
  
      background-color: var(--color-yellow);
  
      content: '';
  
      @include transition(all .2s ease-in-out);
    }

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, .4);
    }

    &:hover .popular-services__item-title, 
    &:hover .popular-services__link,
    &:focus .popular-services__item-title,
    &:focus .popular-services__link {
      transform: translateY(0);
    }

    &:hover::after,
    &:focus::after {
      bottom: 0;
    }

    
  }

  &--a {
    background-image: url('../assets/boat-rentals.png');
  }

  &--b {
    background-image: url('../assets/athens.png');
  }
  
  &--c {
    background-image: url('../assets/octopus.png');
  }

  &--scuba-tour {
    background-image: url('../assets/scuba-tour.png');
  }

  &--auto-moto {
    background-image: url('../assets/auto-moto-header.png');
  }

  &--tours {
    background-image: url('../assets/beach.png');
  }

}


.popular-services__item-title {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include transition(all .2s ease-in-out);

  align-self: stretch;

  transform: translateY(2rem);
}

.popular-services__item-heading {
  @extend .popular-tours__item-heading;

  font-size: 1.6em;

  color: var(--color-white);
}

.popular-services__item-count {
  @extend .paragraph;

  color: var(--color-white)
}

.popular-services__link {
  @include transition(all .2s ease-in-out);
  @extend .features__heading;
  
  color: var(--color-yellow);

  transform: translateY(4rem);

}