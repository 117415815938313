.spinner {
  @include flex-center;
  @include flex-direction(column);
  
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(48, 46, 128, .65);
}

.spinner__list {
  @include flex-center;
  @include no-bullets;

  padding: 0;
  margin: 0;
}

.spinner__single {
  &:not(:last-child) {
    margin-right: .8rem;
  }
}

// SPINNER LOADER
$size: 2em;
$duration: .75s;
$border-radius: $size;
$bg-color: #f5f6f6;

.spinner__dot {
  display: inline-block;

  width: $size / 2;
  height: $size / 2;
  
  margin: $size * .125;
  
  background-color: $bg-color;
  
  border: 1px solid $bg-color;
  border-radius: $border-radius;

  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  
  transform-origin: 50%;

  &--2 {
    animation-delay: $duration * .25;
  }

  &--3 {
    animation-delay: $duration * .5;
  }
}

// Loader Animations

@-webkit-keyframes rotate {
  0% {
    transform: translateY(0);
  }
  
  30% {
    transform: translateY(-$size / 4);
  }
  
  50% {
    transform: translateY(0);
  }
  
  70% {
    transform: translateY($size / 4);
  }

}
