.popular-tours {
  padding: 10rem 70px;

  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/zakynthos2.png);
  background-size: cover;
  background-position: center;

  overflow: hidden;

  @media only screen and (max-width: $bp-large) {
    padding: 10rem 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 7rem 20px;
  }
}

.popular-tours__header {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);

  margin: 0 0 55px;

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
    @include align-items(flex-start);
  }

}

.popular-tours__heading {
  @extend .heading-secondary;

  color: var(--color-white);
}

.popular-tours__see-all {
  font-size: 1.5em;
  font-style: italic;

  color: var(--color-white);
}

.popular-tours__indicator {
  margin-right: 1.3rem;
}

.popular-tours__list {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  
  padding: 0;
  margin: 55px 0 0;

  list-style-type: none;
}

