*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  font-size: 10px;

@media only screen and (max-width: $bp-largest) {
  // font-size: 8px; 
}

@media only screen and (max-width: $bp-medium) {
  // font-size: 7px; 
}

@media only screen and (max-width: $bp-smallest) {
  font-size: 8px; 
}

}

body {
  box-sizing: border-box;

  // background-color: var(--color-grey-5);

}

a,
a:link,
a:visited {
  text-decoration: none;
}

::selection {
  background-color: var(--color-primary-light);
  color: var(--color-white);
}
