.features {
  padding: 6.5rem 70px;
  margin-top: -1px;

  background-color: var(--color-primary);

  @media only screen and (max-width: $bp-large) {
    padding: 6.5rem 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 5rem 20px;
  }
}

.features__list {
  @extend .features-icon-list;
}

.features__item {
  @include flex(0, 0, 25%);
  @include flexbox;
  @include align-items(flex-start);
  @include justify-content(flex-start);

  padding: 1rem;

  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 50%);

    &:nth-child(-n+2) {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 100%);
    @include justify-content(flex-start);

    &:nth-child(-n+2) {
      margin-bottom: 2.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

  }

}
