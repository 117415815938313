.join-us {
  padding: 0 10rem;
  margin-bottom: 70px;

  background-image: linear-gradient(to right,rgba(111, 108, 176, .5) 1%, rgba(0, 0, 0, .3)), url(../assets/boat-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  overflow: hidden;

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px;
    margin-bottom: 0;

    background-attachment: scroll;
  }
}

.join-us-wrapper {
  height: 550px;

  @media only screen and (max-width: $bp-medium) {
    @include justify-content(center);
  }
}

.join-us__description {
  align-self: center;
  
  padding: 5rem;
  margin-left: auto;

  background-color: rgba(0, 0, 0, .3);

  border-radius: 5px;

  @media only screen and (max-width: $bp-small) {
    padding: 3.5rem;

    background-color: rgba(0, 0, 0, .5);
  }
}

.join-us__heading {
  @extend .slider__main-heading;

  color: #fff;
}

.join-us__heading--highlited {
  color: var(--color-yellow);
}

.join-us__description-text {
  font-size: 2.2rem;

  letter-spacing: 1px;

  color: #fff;
}