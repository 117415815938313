.category-all-items__item {
  @include flexbox;

  box-shadow: var(--box-shadow-small);

  border-radius: 3px;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
  }
}

.category-all-items__item-img-link {
  @include flex(0, 0, 35%);

  display: block;

}

.category-all-items__item-img {
  // @include flex(0, 0, 35%);

  position: relative;

  height: 100%;

  background-size: cover;
  background-position: center;
  background-color: #fff;

  &.img-rentals {
    background-size: 90%;
    background-repeat: no-repeat;

    @media only screen and (max-width: $bp-medium) {
      background-size: 70%;
    }

    @media only screen and (max-width: $bp-small) {
      background-size: 90%;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    min-height: 25rem;
  }

  &--tourtles-tour {
    background-image: url('../assets/tourtle-tour.png');
  }

  &--tourtles-tour-sunset {
    background-image: url('../assets/tourtle-tour-sunset.png');
  }

  &--shipwreck-tour {
    background-image: url('../assets/shipwreck-tour.png');
  }

  &--shipwreck-tour-small {
    background-image: url('../assets/shipwreck-small-boat.png');
  }

  &--kefalonia-tour {
    background-image: url('../assets/kefalonia-tour.png');
  }

  &--olympia-tour {
    background-image: url('../assets/olympia-tour.png');
  }

  &--real-zak {
    background-image: url('../assets/real-zak.png');
  }

  &--scuba-tour {
    background-image: url('../assets/scuba-tour.png');
  }

  &--horse-tour {
    background-image: url('../assets/horse-tour.png');
  }

  &--fishing-tour {
    background-image: url('../assets/fishing-tour.png');
  }

  &--cave-tour {
    background-image: url('../assets/cave-cruises.png');
  }

  &--mercedes {
    background-image: url('../assets/bus1-b.jpg');
  }

  &--bus-big {
    background-image: url('../assets/50bus.jpg');
  }

  &--bus-medium {
    background-image: url('../assets/van-3.png');
  }

  &--bus-mini {
    background-image: url('../assets/8-seat.jpg');
  }

  &--scooter-small {
    background-image: url('../assets/scooter-50CC.jpg');
  }

  &--quad {
    background-image: url('../assets/quad300.jpg');
  }

  &--kia-picanto {
    background-image: url('../assets/kia-picanto.jpg');
  }

  &--ford-focus {
    background-image: url('../assets/ford-focus.png');
  }

  &--hyundai-i20 {
    background-image: url('../assets/hyundai-i20.png');
  }

  &--kia-rio {
    background-image: url('../assets/kia-rio.png');
  }

  &--suzuki-jimny {
    background-image: url('../assets/suzuki-jimny.png');
  }

  &--jet-ski {
    background-image: url('../assets/jet-ski.jpg');
  }

  &--motor-boat {
    background-image: url('../assets/motor-boat.png');
  }

  &--speed-boat {
    background-image: url('../assets/speed-boat-1.png');
  }

  &--nireus {
    background-image: url('../assets/nireus-2.png');
  }

  &--poseidon {
    background-image: url('../assets/poseidon-1.png');
  }

  &--round-trip {
    background-image: url('../assets/day-tour.png');
  }

  &--around-island-cruise {
    background-image: url('../assets/zante-night.png');
  }

  &--sunset-tour {
    background-image: url('../assets/sunset-tour.png');
  }

  &--jeep-safari {
    background-image: url('../assets/jeep-safari.png');
  }

  &--water-park {
    background-image: url('../assets/water-park.png');
  }

  &--archery {
    background-image: url('../assets/archery-list.png');
  }

  &--avalon {
    background-image: url('../assets/avalon-hotel.png');
  }

  &--diana {
    background-image: url('../assets/diana-hotel.png');
  }

  &--strada-marina {
    background-image: url('../assets/strada-hotel.png');
  }

  &--plaza {
    background-image: url('../assets/plaza-hotel.png');
  }

  &--phoenix {
    background-image: url('../assets/phoenix-hotel.png');
  }

  &--yria {
    background-image: url('../assets/yria-hotel.png');
  }

  &--alba {
    background-image: url('../assets/alba-hotel.png');
  }

  &--edelweiss {
    background-image: url('../assets/edelweiss-hotel.png');
  }
}

.category-all-items__item--promo {
  .category-all-items__item-promo-label {
    position: absolute;

    @include flex-center;

    width: 37%;
    height: 4.2rem;

    top: 10%;
    left: -4%;

    border-radius: 2px;

    background-color: var(--color-red);

    //TODO: improve this element.

    &::after {
      position: absolute;

      bottom: -.4rem;
      left: .1rem;

      width: 0;
      height: 0;

      border-left: .9rem solid transparent;
      border-right: .9rem solid transparent;

      border-top: .9rem solid rgba(232, 94, 52, 0.5);

      content: '';

      transform: rotate(-134deg);
    }
  }
}

.category-all-items__item-promo-label {
  display: none;
}

.category-all-items__item-promo-text {
  @extend .features__heading
}

.category-all-items__item-details {
  @include flex(1);
  @include flexbox;
  @include justify-content(space-between);

  height: 28rem;

  padding: 3.2rem;

  background-color: var(--color-white);

  @media only screen and (max-width: $bp-largest) {
    height: 34rem;

    padding: 2.5rem;
  }

  @media only screen and (max-width: $bp-large) {
    height: 24rem;

    padding: 2.5rem;
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);

    height: auto;

    padding: 1.5rem;
  }
}

.category-all-items__item-description {
  @include flex(0, 0, 65%);

  @media only screen and (max-width: $bp-medium) {
    @include flex(auto);

    margin-bottom: 2rem;
  }
}

.category-all-items__item-heading {
  @extend .category-page__item-heading;
}

.category-all-items__item-feature {
  @include flexbox;
  @include align-items(center);

  margin-bottom: 1.5rem;
}

.category-all-items__item-feature-icon {
  @include flexbox;

  margin-right: .9rem;
}

.category-all-items__item-feature-label {
  @extend .popular-tours__duration-time;
}

.category-all-items__paragraph {
  @extend .paragraph;
}

.category-all-items__item-pricing {
  @include flex(0, 0, 30%);
  @include flexbox;
  @include flex-direction(column);

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
  }
}

.category-all-items__item-price {
  @extend .category-page__item-price;

  color: var(--color-primary);
}

.category-all-items__item-price--previous {
  @extend .features__paragraph;

  display: block;

  text-align: left;
  text-decoration: line-through;
}

.category-all-items__item-price-label {
  @extend .paragraph;
}

.category-all-items__item-review {
  @include flexbox;
  margin-bottom: auto;

  @media only screen and (max-width: $bp-medium) {
    margin-bottom: 0;
  }
}

.category-all-items__item-stars-single {
  @include flexbox;

  &:not(:last-child) {
    margin-right: .3rem;
  }
}

.category-all-items__link {
  @extend .button;
}
