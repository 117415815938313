.footer__contact-address {
  @extend .paragraph;

  font-style: normal;
}

.footer__text-label {
  margin-right: .7rem;
}

.footer__contact-link {
  @extend .paragraph;

}
