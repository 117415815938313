.contact-page {
  padding: 6rem 70px;

  @media only screen and (max-width: $bp-large) {
    padding: 6rem 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 7rem 20px;
  }

  .sidebar {
    @media only screen and (max-width: $bp-largest) {
      flex: 0 0 36%;
    }

    @media only screen and (max-width: $bp-large) {
      flex: 0 0 49%;
    }

    @media only screen and (max-width: $bp-medium) {
      flex: 0 0 100%;
    }
  }

  .sidebar__item {
    @media only screen and (max-width: $bp-medium) {
      padding-left: .9rem;
    }
  }

  .sidebar__heading {
    @media only screen and (max-width: $bp-medium) {
      padding-left: .9rem;

      font-size: 1.9rem;
    }
  }

}

.contact-page__content {
  @include flexbox;
  @include flex-direction(column);

  @media only screen and (max-width: $bp-medium) {
    margin-bottom: 3rem;
  }
}

.contact-page__heading {
  @include flexbox;
  @include align-items(center);

  @extend .heading-secondary;

  color: var(--color-black);
}

.contact-page__icon {
  @include flexbox;

  margin-right: 1.7rem;

  color: var(--color-primary-light);
}

.contact-page__contact-address {
  @extend .paragraph;

  font-style: normal;
}

.contact-page__text-label {
  margin-right: .7rem;
}

.contact-page__contact-link {
  @extend .paragraph;

}

