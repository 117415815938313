.sidebar {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0, 0, 30%);

  position: relative;

  align-self: flex-start;

  max-width: 100%;

  .support {
    @include flex(auto);

    @media only screen and (max-width: $bp-large) {
      @include flex(0, 0, 47%);

      height: auto;
    }
  }

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 100%);
    @include flex-direction(row);
    @include justify-content(space-between);
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
  }
}
