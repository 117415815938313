.footer__services-list {
  @include no-bullets;
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);

  height: 100%;

  padding: 0;
  margin: 0;
}

.footer__services-single {
  @include flex(0, 0, 47%);
  margin-bottom: 1.5rem;

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 100%);
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 48%);
  }
}

.footer__services-link {
  position: relative;

  display: block;

  min-width: 134px;
  height: 140px;

  background-size: cover;
  background-position: center;

  &--shipwreck-tour {
    background-image: url('../assets/shipwreck-tour.png');
  }

  &--olympia-tour {
    background-image: url('../assets/olympia-tour.png');
  }

  &--fishing-tour {
    background-image: url('../assets/fishing-tour.png');
  }

  &--sunset-tour {
    background-image: url('../assets/sunset-tour.png');
  }

  &--jeep-safari {
    background-image: url('../assets/jeep-safari.png');
  }

  &--round-trip {
    background-image: url('../assets/real-zak.png');
  }
}

.footer__services-img {
  max-width: 100%;
  height: auto;
}

.footer__text-services-heading {
  @include transform(translate(-50%, -50%));
  position: absolute;

  top: 50%;
  left: 50%;
  text-align: center;

  @extend .heading-tertiary;

  color: var(--color-white);

  padding: 0 3px;

  background-color: rgba(48, 46, 128, .65);

  transform: translate(-50%, -50%);
}
