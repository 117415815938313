.category-list {
  padding: 0 70px 70px;

  @media only screen and (max-width: $bp-large) {
    padding: 0 40px 70px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 3rem 20px 30px;
  }
}

.category-list__wrapper {
  @include flex(0, 0, 60%);

  @media only screen and (max-width: $bp-medium) {
    margin-bottom: 30px;
  }
}

.category-list__header {
  margin-bottom: 3.2rem;
  
  text-align: left;
}

.category-list__heading {
  @extend .heading-secondary;
  @include flexbox;
  @include align-items(center);

  color: var(--color-black);
}

.category-list__icon {
  @include flexbox;
  @include rotate(90deg);

  margin-right: 1.7rem;

  color: var(--color-primary-light);
}

.category-list__list {
  @include no-bullets;
  
  padding: 0;
  margin: 0;

  column-count: 2;
}

.category-list__item {
  margin-bottom: 1.4rem;
}

.category-list__link {
  @include transition(all .3s ease-in-out);
  @extend .paragraph;

  color: var(--color-primary-light);

  &:hover {
    color: var(--color-primary-dark);
  }
}
