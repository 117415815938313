.loading {
  @include flex-center;
  
  padding: 260px 25px 80px;

  background-color: var(--color-gray-5);

  @media only screen and (max-width: $bp-large) {
    padding: 80px 25px;
  }
}

.loading__paragraph {
  color: #000;

  font-size: 26px;

  text-align: center;
  
}