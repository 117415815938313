.header {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;

  padding: 25px 70px;

  z-index: 5;

  background-image: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .4));


  @media only screen and (max-width: $bp-large) {
    position: relative;

    padding: 25px 40px;
    
    background-color: var(--color-primary);
  }
  
  @media only screen and (max-width: $bp-medium) {
    padding: 25px 20px; 
  }

}

.header__wrapper {
  @include flex-center;
}
