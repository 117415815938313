.popular-services {
  background-color: var(--color-white);
}

.popular-services__section-wrapper {
  padding: 10rem 70px 0;

  @media only screen and (max-width: $bp-large) {
    padding: 10rem 40px 0;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 7rem 20px 0;
  }
}

.popular-services__header {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);

  margin-bottom: 20px;

  @media only screen and (max-width: $bp-medium) {
    @include flex-direction(column);
    @include align-items(flex-start);
  }

}


.popular-services__heading {
  @extend .heading-secondary;

  @include flexbox;
  @include align-items(center);

  color: var(--color-black);

  @media only screen and (max-width: $bp-small) {
    margin-bottom: 1.6rem;
  }
}

.popular-services__icon {
  @include flexbox;

  margin-right: 1.7rem;

  color: var(--color-red);
}

.popular-services__see-all {
  font-size: 1.5em;
  font-style: italic;

  color: var(--color-primary-dark);
}

.popular-services__indicator {
  margin-right: 1.3rem;
}

.popular-services__list {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  
  padding: 0 0 70px;
  margin: 70px 0 0;

  list-style-type: none;

  @media only screen and (max-width: $bp-large) {
    @include justify-content(center);
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px 30px;
  }
}

.popular-services__description {
  width: 70%;

  @media only screen and (max-width: $bp-medium) {
    width: 100%;
  }
}

.popular-services__description-text {
  @extend .paragraph;
}
