.book-with-us {
  padding: 0 70px;
  margin-bottom: -1px;

  background-image: linear-gradient(to right, var(--color-primary) 20%, var(--color-primary-light) 70%);

  @media only screen and (max-width: $bp-large) {
    padding: 0 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px;
  }
}

.book-with-us__wrapper {
  @include flex-wrap(wrap);
  @extend .container--flex;

  @media only screen and (max-width: $bp-largest) {
    @include justify-content(center);
  }

}

.book-with-us__title {
  @include flex(0, 0, 20%);
  @include flex-center;

  padding: 4rem 0;

  @media only screen and (max-width: $bp-largest) {
    @include flex(0, 0, 100%);

    padding: 4rem 0 0;
  }
}

.book-with-us__heading {
  @extend .slider__paragraph;
  
  margin-right: 2rem;

  @media only screen and (max-width: $bp-large) {
    font-size: 2.2rem;
  }
}

.book-with-us__details-list {
  @include flex(0, 0, 77%);
  
  @extend .features-icon-list;

  padding: 4rem 0;

  @media only screen and (max-width: $bp-largest) {
    @include flex(0, 0, 100%);
  }
}

.book-with-us__details-item {
  @include flex(0, 0, 33%);
  @include flexbox;
  @include align-items(flex-start);
  @include justify-content(flex-start);

  padding: 1rem;

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 100%);

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
}