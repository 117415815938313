.category-header {
  background-size: cover;
  background-position: bottom;

  padding: 180px 70px 0;

  overflow: hidden;

  &--boat-rentals {
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url('../assets/boat-rentals.png');
  }

  &--accommodation {
    background-image: url('../assets/accommodation-header.png');
  }

  &--transport {
    background-image: url('../assets/transport-header.png');
  }

  &--cruises {
    background-image: url('../assets/cruises-header.png');
  }

  &--auto-moto {
    background-image: url('../assets/auto-moto-header.png');
  }

  &--tours {
    background-image: url('../assets/tours-header.png');
  }

  &--contact-page {
    background-image: url('../assets/contact-page.png');
  }


  @media only screen and (max-width: $bp-large) {
    padding: 0 40px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0 20px;
  }

}

.category-header__wrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-start);

  min-height: 24rem;

  @media only screen and (max-width: $bp-large) {
    min-height: 34rem;
  }

  @media only screen and (max-width: $bp-large) {
    min-height: 31rem;
  }
}

.category-header__heading {
  @extend .category-page__heading;

  padding: 0 8px;

  background-color: rgba(48, 46, 128, .65);
}
