.whatsapp-button__wrapper {
  position: fixed;
  z-index: 100;
  bottom: 55px;
  right: 55px;

  @media only screen and (max-width: $bp-medium) {
    bottom: 25px;
    right: 25px;
  }
}

.whatsapp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: #fff;
  box-shadow: var(--box-shadow-medium);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out;

  @media only screen and (max-width: $bp-medium) {
    width: 70px;
    height: 70px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//.whatsapp-button {
//  &:hover {
//    transform: scale(1.05);
//    box-shadow: var(--box-shadow-medium);
//  }
//}

.whatsapp-button--animation {
  background-color: var(--color-success);

  .icon {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: var(--box-shadow-medium);
  }

  ::after {
    position: absolute;
    top: 50%;
    width: 110px;
    height: 110px;
    border-radius: 100rem;
    box-shadow: 0 0 12rem rgba(0, 159, 157, 0.7);
    content: "";
    background-color: rgba(0, 159, 157, 0.5);
    opacity: 0;
    transform: translateY(-50%) scale(0);
    z-index: -1;
    animation: pulse 1.5s forwards linear infinite;

    @media only screen and (max-width: $bp-medium) {
      width: 80px;
      height: 80px;
    }
  }
}

.whatsapp-button--sub {
  width: 55px;
  height: 55px;
  margin: 0 auto 25px;
  padding: 0;
  overflow: hidden;

}

.whatsapp-button__message {
  position: absolute;
  bottom: 3px;
  left: -198px;
  width: 180px;
  padding: 1rem 1.5rem;
  border-radius: 3px;
  box-shadow: var(--box-shadow-medium);
  background-color: var(--color-grey-5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  .arrow-right {
    position: absolute;
    top: 50%;
    right: -9px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--color-grey-5);
    transform: translateY(-50%);
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
