button {
  border: 0;
}

.button {
  &,
  &:link,
  &:visited {
    @include link-styles;
    @include transition(all, 0.3s);
    color: var(--color-grey-1);
    display: inline-block;

    letter-spacing: 1.5px;

    text-align: center;

    cursor: pointer;
  }

  &:hover,
  &:focus {
    @include box-shadow(var(--box-shadow-small));
    @include transform(translateY(-0.3rem));
  }

  &--primary {
    padding: 1rem 4rem;

    font-size: 1.6rem;

    background-color: var(--color-primary);

    border-radius: 0.5rem;

    &:hover,
    &:focus {
      background-color: var(--color-primary-light);
    }

    @media only screen and (max-width: $bp-medium) {
      padding: 1.7rem 3rem;

      font-size: 1.3rem;
    }
  }

  &--secondary {
    padding: 2rem 6rem;

    background-color: var(--color-primary-light);

    border-radius: 3rem;

    font-size: 1.2rem;

    &:hover,
    &:focus {
      background-color: var(--color-yellow);
    }
  }

  &--small {
    padding: 1rem;

    background-color: var(--color-primary-light);
  }

  &--submit {
    padding: 2rem;

    background-color: var(--color-primary-light);
  }
}
