.single-offer {
  @include flex(1);

  @media only screen and (max-width: $bp-large) {
    margin-bottom: 50px;
  }

  article:not(:last-child) {
    padding-bottom: 70px;

    border-bottom: 1px solid var(--color-grey-1);

    @media only screen and (max-width: $bp-medium) {
      padding-bottom: 30px;
    }
  }

  article:not(:first-child) {
    padding-top: 70px;

    @media only screen and (max-width: $bp-medium) {
      padding-top: 30px;
    }
  }
}

.single-offer__details,
.single-offer__description {
  width: 85%;

  @media only screen and (max-width: $bp-largest) {
    width: 93%;
  }

  @media only screen and (max-width: $bp-large) {
    width: 100%;
  }
}

.single-offer__article-header {
  @include flexbox;
  @include align-items(center);
}

.single-offer__heading {
  @extend .slider__subheading;

  font-weight: bold;

  color: var(--color-black);
}

.single-offer__icon {
  @include flexbox;

  margin-right: 1.5rem;

  color: var(--color-primary-light);
}

.single-offer__paragraph {
  @extend .paragraph;
}

.single-offer__list,
.single-offer__description-list {
  @include no-bullets;

  padding: 0;
}

.single-offer__item {
  @include flexbox;
  @include align-items(flex-start);
  @include justify-content(space-between);

  padding: 2.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-1);
  }

  @media only screen and (max-width: $bp-small) {
    @include flex-direction(column);
  }
}

.single-offer__item-label {
  @extend .single-offer-overview__item-text;

  padding-right: 1.5rem;

  color: var(--color-black);
}

.single-offer__item-features {
  @include flex(0, 0, 35%);
  @include no-bullets;

  padding: 0;
  margin: 0;

  @media only screen and (max-width: $bp-largest) {
    @include flex(0, 0, 45%);
  }

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 35%);
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 45%);
  }

  @media only screen and (max-width: $bp-small) {
    margin-top: 2rem;
  }
}

.single-offer__item-single-feature {
  @include flexbox;
  @include align-items(flex-start);
  @include justify-content(flex-start);

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.single-offer__item-single-feature--only {
  @include flex(0, 0, 35%);

  @media only screen and (max-width: $bp-largest) {
    @include flex(0, 0, 45%);
  }

  @media only screen and (max-width: $bp-large) {
    @include flex(0, 0, 35%);
  }

  @media only screen and (max-width: $bp-medium) {
    @include flex(0, 0, 45%);
  }

  @media only screen and (max-width: $bp-small) {
    margin-top: 2rem;
  }
}

.single-offer__description-item {
  @include flexbox;
  @include align-items(center);

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.single-offer__gallery {
  @include flexbox;
  @include justify-content(flex-start);
  @include flex-wrap(wrap);
  @include no-bullets;

  padding: 3.5rem 0 0;
  margin: 0;
}

.single-offer__gallery-item {
  @include flex(0, 0, 44%);
  @include flex-center;

  margin: 0 1.5rem 3rem;

  @media only screen and (max-width: $bp-small) {
    @include flex(0, 0, 100%);

    margin: 0 0 3rem;
  }
}

.single-offer__gallery-item--full {
  @include flex(0, 0, 100%);
  @include flex-center;
  margin: 0 0 3rem;
}

.single-offer__gallery-img {
  display: block;

  max-width: 100%;
  height: auto;
}
