.sidebar__features--padding-bottom {
  padding: 0 0 4.5rem;
}

.sidebar__features--single-offer {
  padding: 2.5rem 0 1.5rem;
  margin: 69rem 0 4rem;

  background-color: var(--color-grey-5);

  @media only screen and (max-width: $bp-large) {
    margin: 0 0 5rem;
  }

  @media only screen and (max-width: $bp-medium) {
    margin: 5rem 0;
  }

}

.sidebar__heading {
  @extend .features__heading;

  padding: 0 2.7rem;

  text-transform: none;

  color: var(--color-black);
}

.sidebar__list {
  @include no-bullets;

  padding: 0;
}

.sidebar__item {
  @include flexbox;
  @include align-items(center);
  
  padding: 1.3rem 2.7rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-1);
  }
}

.sidebar__item-icon {
  @include flexbox;

  margin-right: 1rem;

  color: var(--color-yellow);
}

.sidebar__item-paragraph {
  @extend .features__paragraph;
}
